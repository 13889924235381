import React, { useEffect, useState } from 'react';
import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle, IonPage, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';
import DefaultPage from '../components/DefaultPage';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import { contrastOutline, gridOutline, helpCircle, languageOutline, logOut, musicalNotes, personCircle, settings, statsChart } from 'ionicons/icons';
import WordsModal from '../components/WordsModal';
import WordsList from '../components/WordsList';
import { Word } from '../types/types.';

const SavedWords: React.FC<RouteComponentProps> = (props) => {
  const { user, getUserWords } = useGlobalContext();
  const [showModal, setShowModal] = useState(false);
  const [userWords, setUserWords] = useState<Word[] | null>(null);
  useEffect(() => {
    console.log("from SavedWords: ", user?.words)
    fetchUserWords();

  }, [user]);
  const fetchUserWords = async () => {
    if (user && user.id) {
      const words = await getUserWords(user.id);
      if (words) {
        setUserWords(words)
      }
    }
  };

  return (
    <>
    
      <DefaultPage>
        <IonCard className="default-card">
          {/* <IonContent> */}
            <div className='default-card-wrapper'>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='default-card__list-title'>Saved Words</p>
                <IonButton onClick={() => setShowModal(true)} className='toggle-btn' fill='clear'>
                  <IonIcon icon={gridOutline} />
                </IonButton>
              </div>
              {userWords && userWords.length > 0 && <WordsList name="Saved Words" words={userWords} />}
            </div>
          {/* </IonContent> */}
        </IonCard>
      </DefaultPage>
      {userWords && (
        <WordsModal words={userWords} showModal={showModal} closeModal={() => setShowModal(false)} />
      )}
    </>
  );
};

export default SavedWords;
