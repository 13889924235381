import React from 'react';
import { IonItem, IonLabel, IonThumbnail, IonButton, IonIcon } from '@ionic/react';
import { pause, play } from 'ionicons/icons';
import { useGlobalContext } from '../contexts/GlobalContext';
import { Song, SpotifyTrack } from '../types/types.';

const NowPlaying: React.FC = () => {
  const { nowPlaying, isPlaying, togglePlaySong } = useGlobalContext();

  if (!nowPlaying) return null;

  const isSong = (track: Song | SpotifyTrack): track is Song => {
    return (track as Song).spotifyId !== undefined;
  };

  const name = isSong(nowPlaying) ? nowPlaying.title : nowPlaying.name;
  const image = isSong(nowPlaying) ? nowPlaying.albumUrl : nowPlaying.album.images[0]?.url;
  const artist = isSong(nowPlaying) ? nowPlaying.artist : nowPlaying.artists.map(artist => artist.name).join(', ');

  return (
    <div className='now-playing-wrapper'>
      <IonButton onClick={() => togglePlaySong(nowPlaying)} className='action-btn'>
        <IonIcon icon={isPlaying ? pause : play} />
      </IonButton>
      <IonItem>

        <IonLabel>
          <div className='d-flex align-items-center' style={{ maxWidth: '90%' }}>
            <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</h2>
          </div>
          <i>{artist}</i>
        </IonLabel>
      </IonItem>
    </div>
  );
};

export default NowPlaying;