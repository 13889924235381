import React, { ReactNode, useEffect, useContext, useState } from 'react';
import { IonButton, IonContent, IonHeader, IonPage } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import AppHeader from './AppHeader';

interface DefaultPageProps {
  children?: ReactNode;
}

const DefaultPage: React.FC<DefaultPageProps> = ({ children }) => {
  // document.body.className = 'theme-ocean-breeze'
  document.body.className = 'theme-default'
  const { user, isMobile, showSearch, setShowSearch, spotifyToken, spotifyDeviceId } = useGlobalContext(); // Assuming you have the token in GlobalContext
  const [token, setToken] = useState<string | null>(null);
  // useEffect(() => {
  //   console.log('from DefaultPage. user: ', user);
  //   if (showSearch === null) {
  //     setShowSearch(!isMobile);
  //   }
  //   const params = new URLSearchParams(window.location.hash.slice(1));
  //   const accessToken = params.get('access_token');
  //   const state = params.get('state');

  //   console.log('from DefaultPage. params: ', params);
  //   console.log('from DefaultPage. accessToken: ', accessToken);
  //   if (state && state === 'spotify') {
  //     setToken(accessToken)
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log('from DefaultPage. user: ', user);
  //   if (showSearch === null) {
  //     setShowSearch(!isMobile);
  //   }
  // }, [showSearch, setShowSearch, isMobile, user]);
  // useEffect(() => {
  //   console.log('from DefaultPage. token: ', token);

  // }, [token]);
  return (
    <>
      <IonPage id="main">
        <AppHeader />
        <IonContent className="ion-no-padding">
          {/* <div className="app-content-container"> */}
          {children}
          {/* </div> */}
        </IonContent>
      </IonPage>
    </>
  );
};

export default DefaultPage;
