import React, { useEffect } from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonChip, IonIcon, IonButton, IonText, IonThumbnail } from '@ionic/react';
import { volumeHighOutline, bookOutline, star, addOutline, addCircle, language } from 'ionicons/icons';
import LanguageSelector, { languages } from './LanguageSelector';
import { useGlobalContext } from '../contexts/GlobalContext';
import { Word } from '../types/types.'
import './WordCard.css';

interface WordCardProps {
  word: Word;
  previewMode: string;
  onPlayPronunciation?: (word: string) => void;
  onAddToFavorites?: (word: string) => void;
  onShowDetails?: (word: string) => void;
}

const WordCard: React.FC<WordCardProps> = ({
  word,
  onPlayPronunciation,
  onAddToFavorites
}) => {
  const { speakWord, saveWordToUser, user } = useGlobalContext();
  // const emojiFromHex = (hex: string) => String.fromCodePoint(parseInt(hex, 16));
  // const wordTypeIcon = word.wordTypeId ? emojiFromHex(word.wordTypeDefaultIcon || '') : null;
  const handleSpeakButtonClick = () => {
    const langCode = languages.find(language => language.id === word.languageId)?.code;
    speakWord(word.sourceWord, langCode || 'en-En');
  }

  const handleSaveButtonClick = () => {
    console.log("handleSaveButtonClick");
  }
  return (
    <IonCard className="word-card">
      <IonCardHeader>
        <div className="main-word">
          <div className="source-word">{word.sourceWord}
            <IonButton onClick={handleSpeakButtonClick} fill='clear'>
              <IonIcon icon={volumeHighOutline} slot="start" />
            </IonButton>
          </div>
          <div className="translation">{word.translation}</div>
          <div className="word-type">
            {/* <span className="icon">{wordTypeIcon}</span> */}
            <span>{word.wordTypeTranslation}</span>
          </div>
        </div>
      </IonCardHeader>

      <IonCardContent>
        <div className="example-box">
          <p className="example">{word.example}</p>
          <p className="example-translation">{word.exampleTranslation}</p>
        </div>

        <div className="actions">
          {/* <IonButton onClick={handleSpeakButtonClick}>
            <IonIcon icon={volumeHighOutline} slot="start" />
            שמע
          </IonButton> */}
          <IonButton onClick={handleSaveButtonClick}>
            <IonIcon icon={addOutline} slot="start" />
            שמור
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default WordCard;