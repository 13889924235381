const prod = {
    apiUrl: 'https://o9cbszuryh.execute-api.eu-north-1.amazonaws.com',
    appUrl: 'https://singsmart.fleximind.ai',
    pexelsApiKey: 'HBsqEnKPmOg2AvYdF5l5WeJHoXRXS504a2wImh6ANGOOIPGevxmdregl',
    AWS_POLLY_ACCESS_KEY: 'AKIA2FXAD4IQZWUJ3V3I',
    AWS_POLLY_SECRET_KEY: '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',
    AWS_TRANSLATE_ACCESS_KEY: 'AKIA2FXAD4IQRQMZGQ4I',
    AWS_TRANSLATE_SECRET_KEY: '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',
    UNSPLASH_ACCESS_KEY: '1xFZQVPUhMIkn-y2VWp79t6FVHgqftAtSYioFDqDGHI', 
    UNSPLASH_SECRET_KEY: 'xc8EJXUhiftWU1DDUWuA-ttRUYOiKk3-KjYe8gSFEaw', 
  };
  
  const dev = {
    apiUrl: 'https://o9cbszuryh.execute-api.eu-north-1.amazonaws.com',
    appUrl: 'http://localhost:8100',
    pexelsApiKey: 'HBsqEnKPmOg2AvYdF5l5WeJHoXRXS504a2wImh6ANGOOIPGevxmdregl',
    AWS_POLLY_ACCESS_KEY: 'AKIA2FXAD4IQZWUJ3V3I',
    AWS_POLLY_SECRET_KEY: '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',
    AWS_TRANSLATE_ACCESS_KEY: 'AKIA2FXAD4IQRQMZGQ4I',
    AWS_TRANSLATE_SECRET_KEY: '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',
    UNSPLASH_ACCESS_KEY: '1xFZQVPUhMIkn-y2VWp79t6FVHgqftAtSYioFDqDGHI', 
    UNSPLASH_SECRET_KEY: 'xc8EJXUhiftWU1DDUWuA-ttRUYOiKk3-KjYe8gSFEaw', 
  };
  console.log("env: ", process.env.NODE_ENV)
  export const config = process.env.NODE_ENV === 'production' ? prod : dev;