// import React, { ReactNode, useState } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import type { Swiper as SwiperType } from 'swiper';
// import { EffectCards } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/effect-cards';
// import './CustomSwiper.css';

// const CustomSwiper: React.FC<{
//     children?: ReactNode;
//     onSwipeLeft?: () => void;
//     onSwipeRight?: () => void;
//     onSlideChange?: (index: number) => void;
// }> = ({ children, onSwipeLeft, onSwipeRight, onSlideChange }) => {
//   const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);

//   const handleTouchStart = (e: TouchEvent) => {
//     const touch = e.touches[0];
//     const startX = touch.clientX;
//     const startY = touch.clientY;

//     const handleTouchEnd = (e: TouchEvent) => {
//       const touch = e.changedTouches[0];
//       const diffX = touch.clientX - startX;
//       const diffY = touch.clientY - startY;

//       // אם התנועה האופקית גדולה יותר מהאנכית
//       if (Math.abs(diffX) > Math.abs(diffY) && Math.abs(diffX) > 50) {
//         if (diffX > 0) {
//           onSwipeRight?.();
//         } else {
//           onSwipeLeft?.();
//         }
//       }
//       // אם התנועה האנכית גדולה יותר
//       else if (Math.abs(diffY) > Math.abs(diffX) && Math.abs(diffY) > 50) {
//         if (diffY > 0) {
//           swiperInstance?.slidePrev();
//         } else {
//           swiperInstance?.slideNext();
//         }
//       }

//       document.removeEventListener('touchend', handleTouchEnd);
//     };

//     document.addEventListener('touchend', handleTouchEnd);
//   };

//   return (
//     <div 
//       className="custom-swiper-container"
//       onTouchStart={(e) => handleTouchStart(e.nativeEvent)}
//     >
//       <Swiper
//         modules={[EffectCards]}
//         effect="cards"
//         direction="vertical"
//         slidesPerView={1}
//         onSwiper={setSwiperInstance}
//         onSlideChange={(swiper:any) => onSlideChange?.(swiper.activeIndex)}
//       >
//         {children}
//       </Swiper>
//     </div>
//   );
// };

// export default CustomSwiper;

import React, { ReactNode, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import { EffectCards, EffectFade, EffectFlip, EffectCreative, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/css/effect-cards';
import './CustomSwiper.css';

const CustomSwiper: React.FC<{
    children?: ReactNode;
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
    onSlideChange?: (index: number) => void;
}> = ({ children, onSwipeLeft, onSwipeRight, onSlideChange }) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);

  useEffect(() => {
    const container = document.querySelector('.custom-swiper-container') as HTMLElement;
    if (!container) return;

    const initTouchHandlers = () => {
      const handleTouchStart = (e: TouchEvent) => {
        if (!e.touches[0]) return;
        
        const touch = e.touches[0];
        const startX = touch.clientX;
        const startY = touch.clientY;

        const handleTouchEnd = (e: TouchEvent) => {
          if (!e.changedTouches[0]) return;
          
          const touch = e.changedTouches[0];
          const diffX = touch.clientX - startX;
          const diffY = touch.clientY - startY;

          if (Math.abs(diffX) > Math.abs(diffY) && Math.abs(diffX) > 50) {
            if (diffX > 0) {
              onSwipeRight?.();
            } else {
              onSwipeLeft?.();
            }
          } else if (Math.abs(diffY) > Math.abs(diffX) && Math.abs(diffY) > 50) {
            if (diffY > 0) {
              swiperInstance?.slidePrev();
            } else {
              swiperInstance?.slideNext();
            }
          }

          document.removeEventListener('touchend', handleTouchEnd as EventListener);
        };

        document.addEventListener('touchend', handleTouchEnd as EventListener);
      };

      container.addEventListener('touchstart', handleTouchStart as EventListener);

      return () => {
        container.removeEventListener('touchstart', handleTouchStart as EventListener);
      };
    };

    const cleanup = initTouchHandlers();
    return cleanup;
  }, [swiperInstance, onSwipeLeft, onSwipeRight]);

  return (
    <div className="custom-swiper-container">
      <Swiper
        modules={[EffectCoverflow]}
        effect="coverflow"
        direction="vertical"
        slidesPerView={1}
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper:any) => onSlideChange?.(swiper.activeIndex)}
        observer={true}
        observeParents={true}
      >
        {children}
      </Swiper>
    </div>
  );
};

export default CustomSwiper;