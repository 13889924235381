const DEFAULT_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0iI2YwZjBmMCIvPjxwYXRoIGQ9Ik0zNSwyNSBoMzAgdjUwIGgtMzAgeiBlNDAsNzUgaDIwIE00MCw0NSBoMjAgTTQwLDU1IGgyMCIgc3Ryb2tlPSIjOTk5IiBmaWxsPSJub25lIiBzdHJva2Utd2lkdGg9IjMiLz48dGV4dCB4PSI1MCIgeT0iODAiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGZvbnQtZmFtaWx5PSJBcmlhbCIgZm9udC1zaXplPSIxMiIgZmlsbD0iIzY2NiI+Tm8gSW1hZ2U8L3RleHQ+PC9zdmc+';

import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonThumbnail, IonIcon, IonImg, IonSpinner } from '@ionic/react';
import { playCircleOutline, pauseCircleOutline, openOutline, eyeOutline, saveOutline, downloadOutline, gridOutline, addOutline, planetOutline, volumeHighOutline, refreshCircleOutline, languageOutline, imageOutline } from 'ionicons/icons';
import './SongsList.css';
import {speakWord, translateWord} from '../utils/awsUtils'
import {getUnsplashImageForWord, fetchImages, getPexelsImageForWord} from '../utils/imageUtils'
import { Word } from '../types/types.';
import WordsModal from './WordsModal';

export interface WordsListType {
    name: string;
    words: Word[];
}

const WordsList: React.FC<WordsListType> = ({ name, words }) => {
    const { user, updateWords, speakWord, togglePlaySong, playingTrackId, isPlaying, saveWordToUser, getLyrics, generateLyricsVocabulary, selectedSong, setSelectedSong, saveWords } = useGlobalContext();
    const [showModal, setShowModal] = useState(false);
    const [wordsState, setWordsState] = useState<Word[]>(words);

    useEffect(() => {
        console.log("from WordsList ", words)
    }, []);

    const changeWordImage = async (word: Word) => {
        console.log(word)
        const image = await getPexelsImageForWord(word.englishDescription);
        console.log(image)
        
        if (image) {
            const updatedWords = wordsState.map(w => 
                w.id === word.id ? { ...w, image } : w
            );
            setWordsState(updatedWords);
        }
    }
    const handleSpeakButtonClick = (word: Word) => {
        console.log("handleSpeakButtonClick word: ", word)
        const lang = word.languageCode ? word.languageCode: 'en-EN'
        speakWord(word.sourceWord, lang);
    }
    const handleTranslateButtonClick = async(word: Word) => {
        console.log("handleSpeakButtonClick word: ", word)
        console.log(await translateWord(word.sourceWord, 'en-EN'));
    }
    const saveWordImage = async(word: Word) => {
        const updated =await updateWords([word])
        console.log(updated)
    }
    return (
        <>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='default-card__list-title'>{name}</p>
                <IonButton onClick={() => setShowModal(true)} fill='clear' size='small' className='words-modal-btn'>
                    <IonIcon icon={gridOutline} />
                </IonButton>
                
            </div>
            <div className='default-card__list-wrapper'>
                <IonList>
                    {wordsState.map((word) => (
                        <IonItem key={word.id} >
                            <div className='d-flex  default-card__content-right' style={{ width: '100%', padding: '8px' }}>
                                <IonThumbnail style={{ heigth: '' }}>
                                    <img src={word.image|| DEFAULT_IMAGE} alt={word.sourceWord} />
                                </IonThumbnail>
                                <IonLabel style={{ width: '80%', cursor: 'pointer' }}>
                                    <div className='d-flex align-items-center' style={{ maxWidth: '150px' }}>
                                        <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{word.sourceWord}</h2>
                                        <IonIcon
                                            style={{ cursor: 'pointer' }}
                                            icon={playCircleOutline}
                                            onClick={()=>handleSpeakButtonClick(word)}
                                        />
                                        <IonIcon
                                            style={{ cursor: 'pointer' }}
                                            icon={languageOutline}
                                            onClick={()=>handleTranslateButtonClick(word)}
                                        />
                                    </div>
                                </IonLabel>
                            </div>
                            <div className='action-btns'>
                                <IonButton onClick={() => changeWordImage(word)} className='action-btn'>
                                    <IonIcon icon={refreshCircleOutline} />
                                </IonButton>

                                <IonButton onClick={() => saveWordImage(word)} className='action-btn'>
                                    <IonIcon icon={saveOutline} />
                                </IonButton>
                            </div>
                        </IonItem>
                    ))}
                </IonList>
            </div>
            <WordsModal words={wordsState} showModal={showModal} closeModal={() => setShowModal(false)} />
        </>
    );
};

export default WordsList;

