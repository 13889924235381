import React, { useRef, useEffect, useState } from 'react';
import { IonButton, IonContent, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonModal } from '@ionic/react';
import './WordsContainer.css';
import WordCard from './WordCard';
import { closeOutline } from 'ionicons/icons';
import { SwiperSlide } from 'swiper/react';
import CustomSwiper from './CustomSwiper';
import { User, Word } from '../types/types.'
interface WordsModalProps {
  words: Word[];
  showModal: boolean;
  closeModal: () => void;
}

const WordsModal: React.FC<WordsModalProps> = ({ words, showModal, closeModal }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSwipeLeft = (word: Word) => {
    console.log('Removing word:', word);
    // הוספת לוגיקה למחיקת מילה
  };

  const handleSwipeRight = (word: Word) => {
    console.log('Saving word:', word);
    // הוספת לוגיקה לשמירת מילה
  };

  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={closeModal}
      className='words-modal fullscreen-modal'
    >
      <IonContent fullscreen>
        <IonButton onClick={closeModal} className='close-modal-btn' fill='clear'>
          <IonIcon size='large' icon={closeOutline}></IonIcon>
        </IonButton>
        <CustomSwiper
          onSwipeLeft={() => handleSwipeLeft(words[currentIndex])}
          onSwipeRight={() => handleSwipeRight(words[currentIndex])}
          onSlideChange={setCurrentIndex}
        >
          {words.map((word, index) => (
            <SwiperSlide key={index}>
              <WordCard word={word} previewMode='' />
            </SwiperSlide>
          ))}
        </CustomSwiper>
      </IonContent>
    </IonModal>
  );
};
export default WordsModal;