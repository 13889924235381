import React, { useEffect, useState } from 'react';
import {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonAvatar,
    IonMenuToggle,
    IonSearchbar,
    IonText,
    IonFab,
    IonFabButton
} from '@ionic/react';
import {
    personCircle,
    musicalNotes,
    statsChart,
    settings,
    helpCircle,
    logOut,
    languageOutline,
    search
} from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { SpotifyTrack } from '../types/types.';

const SearchBar: React.FC = () => {
    const history = useHistory();
    const { setSelectedSong, selectedSong, isMobile } = useGlobalContext();
    const [query, setQuery] = useState<string>('');
    const [trackSearchResults, setTrackSearchResults] = useState<SpotifyTrack[]>([]);
    const [showResults, setShowResults] = useState<boolean>(false);
    const navigateToItem = (id: string, type: 'playlist' | 'track' | 'word') => {
        history.push(`/${type}/${id}`);
    };
    useEffect(() => {
       if (!query) {
        setTrackSearchResults([])
       }

    }, [query])

    const handleInput = (ev: Event) => {
        const target = ev.target as HTMLIonSearchbarElement;
        if (target) {
            setQuery(target.value!.toLowerCase());
            searchSong(target.value!.toLowerCase());
        }
    };

    const handleSelect = async (track: SpotifyTrack) => {
        setTrackSearchResults([]);
        history.push(`/track/${track.id}`);
    };

    const searchSong = async (query: string) => {
        console.log(" query ", query);
        if (query) {
            setShowResults(true)
            try {
                setSelectedSong(null)
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/spotifySearch`, { artist:'', title:query });
                console.log("spotifySearch from main response ", response.data);
                if (response.data) {
                    setTrackSearchResults(response.data);
                }
            } catch (error) {
                console.error('Error in searchSong:', error);
                setTrackSearchResults([]);
                setShowResults(false)
            }
        }
        else {
            setShowResults(false)
        }
        
    };

    return (
        <div className={isMobile? 'search-wrapper mobile-view' : 'search-wrapper'}>
            <IonSearchbar
                className='search-input'
                animated={true}
                placeholder="Enter song"
                value={query}
                debounce={1000}
                onIonInput={(ev) => handleInput(ev)}
            />

            {!selectedSong && trackSearchResults.length > 0 && (
                <IonList>
                    {trackSearchResults.map((item, index) => (
                        <IonItem key={index} button onClick={() => handleSelect(item)}>
                            <IonLabel>
                                <IonText color="primary" className='mr-2'>{item.name}</IonText>
                                <IonText color="medium">
                                    <i>{item.artists[0].name}</i>
                                </IonText>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            )}
           
        </div>
    );
};

export default SearchBar;