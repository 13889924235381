import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonThumbnail, IonIcon, IonImg, IonSpinner } from '@ionic/react';
import { playCircleOutline, pauseCircleOutline, openOutline, eyeOutline, saveOutline, downloadOutline, gridOutline, addOutline, planetOutline, volumeHighOutline, add, play, chevronUp, chevronDown } from 'ionicons/icons';
// import './Playlist.css';
import { useHistory } from 'react-router-dom';
import { title } from 'process';
import { Song, SpotifyPlaylist, SpotifyTrack } from '../types/types.';
import SongsList from './SongsList';


export interface Playlist {
    id: string;
    name: string;
    description: string;
    tracks: { total: number };
    external_urls: { spotify: string }
    images: [{ url: '' }]
}

const Playlist: React.FC<{ playlists: SpotifyPlaylist[] }> = ({ playlists }) => {
    const { spotifyToken, user, saveSong, updateSong, getSongData, saveUserSong, getUserPlaylists, getPlaylistTracks, speakWord, togglePlaySong, playingTrackId, isPlaying, getLyrics, generateLyricsVocabulary, selectedSong, setSelectedSong, saveWords } = useGlobalContext();
    const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(null);
    const [playlistTracks, setPlaylistTracks] = useState<SpotifyTrack[]>([]);
    const [showPlaylists, setShowPlaylists] = useState<boolean>(true);
    const [cardTitle, setCardTitle] = useState<string>("Spotify Playlists");
    const [showPlaylistTracks, setShowPlaylistTracks] = useState<boolean>(false);
    const [showListItems, setShowListItems] = useState<boolean>(true);
    const handlePlaylistSelect = async (playlist: SpotifyPlaylist) => {
        console.log("handlePlaylistSelect: ", playlist)
        if (user?.spotifyAccesToken && playlist.id) {
            const tracks = await getPlaylistTracks(user?.spotifyAccesToken, playlist.id)
            if (tracks) {
                setSelectedPlaylist(playlist)
                setPlaylistTracks(tracks)
                setShowPlaylistTracks(true)
                setShowListItems(false)
            }
        }
    };

    return (
        <div>
            {showPlaylists && (
                <div className='default-card-wrapper'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 className='default-card__list-title'>My Playlists</h2>
                        <IonButton onClick={() => setShowListItems(!showListItems)} className='toggle-btn' fill='clear'>
                            <IonIcon icon={showListItems ? chevronUp : chevronDown} />
                        </IonButton>
                    </div>
                    {showListItems && (
                        <IonList>
                            {playlists.filter(p => p.name !== '').map((pl) => (
                                <IonItem key={pl.id}>
                                    <div className='d-flex default-card__content-right' onClick={() => handlePlaylistSelect(pl)}>
                                        <IonThumbnail style={{ heigth: '' }}>
                                            <img src={pl.images[0]?.url} alt={pl.name} />
                                        </IonThumbnail>
                                        <IonLabel style={{ width: '80%', cursor: 'pointer' }}>
                                            <div className='d-flex align-items-center'>
                                                <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{pl.name}</h2>
                                            </div>
                                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <i>{pl.description}</i>
                                            </div>
                                        </IonLabel>
                                    </div>
                                </IonItem>
                            ))}
                        </IonList>
                    )}
                </div>
            )}
            {selectedPlaylist && showPlaylistTracks && playlistTracks.length > 0 && (
                <SongsList name={selectedPlaylist.name} tracks={playlistTracks} />
            )}
        </div>
    );
};

export default Playlist;

