
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import { User, Word, Song, Sentence } from '../types/types.'
import WordsModal from './WordsModal';
import { IonButton, IonIcon } from '@ionic/react';
import { gridOutline } from 'ionicons/icons';

// מפתח קבוע לשמירה בlocalstorage
const TEMP_SONG_KEY = 'temp_song_data';

export interface SongDataType {
    song: Song;
}

const SongDetails: React.FC<SongDataType> = ({ song }) => {
    const { getTanslatedSongData } = useGlobalContext();
    const [songWords, setSongWords] = useState<Word[] | null>(null);
    const [songSentences, setSongSentences] = useState<Sentence[] | null>(null);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if (song?.sentences) {
            setSongSentences(song.sentences)
        }
        if (song?.words) {
            setSongWords(song.words)
        }
        // בדיקה אם יש מידע בlocalstorage
        // const cachedData = localStorage.getItem(TEMP_SONG_KEY);
        // if (cachedData) {
        //     const { words, sentences } = JSON.parse(cachedData);
        //     setSongWords(words);
        //     setSongSentences(sentences);
        //     console.log("Loading from cache");
        //     return;
        // }

        // אם אין מידע בcache, מביאים מהשרת
        // fetchSongTranslatedData(song);
    }, []);
    // const fetchSongWordsData = async () => {
    //     if (song.id) {
    //         const words = await getWordsBySong(song.id)
    //         console.log("words from songdetails: ", words)
    //         const wordsWithoutImage = words.filter(w => !w.image)
    //         console.log("wordsWithoutImage : ", wordsWithoutImage)
    //         // const proccessedWordsImage =  
    //         setSongWords(words)
    //     }
    // };
    // const insertSongSentences = async (lyrics: string, songId: number) => {
    //     const songSentencesResponse = await generateLyricsSentences(lyrics, song.languageId || 8, 3);
    //     if (songSentencesResponse.sentences.length) {
    //         const result = await saveSongSentences(songSentencesResponse.sentences, songId);
    //         console.log("songSentencesResponse result:", result);
    //     }
    // }
    const fetchSongTranslatedData = async (song: Song) => {
        if (song.spotifyId) {
            try {
                const fullData = await getTanslatedSongData(song.spotifyId, "3");
                console.log("fullData", fullData);

                if (fullData?.sentences || fullData?.words) {
                    // שמירה בlocalstorage
                    if (fullData.words) {

                        const words = fullData.words.filter(w=>w.translation !==null)
                        localStorage.setItem(TEMP_SONG_KEY, JSON.stringify({
                            words: words,
                            sentences: fullData.sentences || [],
                            timestamp: new Date().getTime()
                        }));
                        if (fullData?.sentences) {
                            setSongSentences(fullData.sentences)
                        }
                        if (fullData?.words) {
                            setSongWords(fullData.words.filter(w=>w.translation !==null))
                        }
                    }
                    
                }
            } catch (error) {
                console.error("Error fetching song data:", error);
            }
        }
    };

    if (!song) {
        return null;
    }

    return (
        <div className='default-card'>
            <div className='default-card-wrapper'>
                <div className='d-flex justify-content-between align-items-center'>
                    <p className='default-card__list-title'>{song.title} / {song.artist}</p>
                    <IonButton onClick={() => setShowModal(true)} fill='clear' size='small' className='words-modal-btn'>
                        <IonIcon icon={gridOutline} />
                    </IonButton>
                </div>
                <div className='song_data'>
                    <div className="sentence-translation-list">
                        {songSentences && songSentences.map((item, index) => (
                            <div key={index} className="sentence-translation-item">
                                <p className="sentence">{item.originalText}</p>
                                <p className="translation">{item.translation}</p>
                            </div>
                        ))}
                    </div>
                </div>
                {songWords && songWords.length > 0 && (
                    <WordsModal words={songWords} showModal={showModal} closeModal={() => setShowModal(false)} />

                    // <WordsList name={`${song.title} / ${song.artist}`} words={songWords} />
                )}
            </div>
        </div>
    );
};

export default SongDetails;