import { Polly } from 'aws-sdk';
import { TranslateClient, TranslateTextCommand } from "@aws-sdk/client-translate";

import { config } from '../config';
const polly = new Polly({
    region: 'us-east-1', 
    credentials: {
        accessKeyId: config.AWS_POLLY_ACCESS_KEY,
        secretAccessKey: config.AWS_POLLY_SECRET_KEY
    }
});
const translateClientclient = new TranslateClient({
  region: "eu-west-1",
  credentials: {
    accessKeyId: config.AWS_TRANSLATE_ACCESS_KEY,
    secretAccessKey: config.AWS_TRANSLATE_SECRET_KEY
  }
});
const voiceMap: { [key: string]: string } = {
    'he-IL': 'Dalia',
    'en-US': 'Joanna',
    'fr-FR': 'Lea',
    'it-IT': 'Carla',
    'es-ES': 'Lucia',
    'de-DE': 'Marlene',
    'ar-SA': 'Zeina',
    'ru-RU': 'Tatyana',
    'ja-JP': 'Mizuki',
    'ko-KR': 'Seoyeon',
    'zh-CN': 'Zhiyu'
};
export const speakWord = async (word: string, lang: string) => {
    try {
        const params = {
          Text: word,
          OutputFormat: 'mp3',
          VoiceId: voiceMap[lang] || 'Joanna',
          LanguageCode: lang
        };
    
        const response = await polly.synthesizeSpeech(params).promise();
        
        if (response.AudioStream) {
          const audioBlob = new Blob([response.AudioStream as unknown as ArrayBuffer], { type: 'audio/mp3' });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          await audio.play();
        }
      } catch (error) {
        console.error('Polly error:', error);
      }
};

export const translateWord = async (text: string, targetLang: string) => {
  const command = new TranslateTextCommand({
    Text: text,
    SourceLanguageCode: "auto",
    TargetLanguageCode: targetLang
  });
  
  const response = await translateClientclient.send(command);
  return response.TranslatedText;
};