import React, { useEffect, useState } from 'react';
import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle, IonPage, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';
import DefaultPage from '../components/DefaultPage';
import LanguageSelector from '../components/LanguageSelector';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import { contrastOutline, helpCircle, languageOutline, logOut, musicalNotes, personCircle, settings, statsChart } from 'ionicons/icons';
import ThemeSelector from '../components/ThemeSelector';

const UserPage: React.FC<RouteComponentProps> = (props) => {
  const history = useHistory();
  // const { logoutSpotifyUser } = useGlobalContext();

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>('');
  const handleThemeChange = (theme: string) => {
    console.log(`Theme changed to: ${theme}`);
    // כאן תוכלי להוסיף לוגיקה נוספת אם נדרש
  };
  const logout = () => {
    console.log("logout");  // Call logout to clear the user session
    // history.push('/login');  // Then handle the redirect in the component
  };
  const getTimeOfDay = () => {
    const now = new Date();
    const hour = now.getHours();

    let timeOfDay;

    if (hour >= 5 && hour < 12) {
      timeOfDay = "morning";
    } else if (hour >= 12 && hour < 18) {
      timeOfDay = "afternoon";
    } else if (hour >= 18 && hour < 22) {
      timeOfDay = "evening";
    } else {
      timeOfDay = "night";
    }
    return timeOfDay;
  }

  return (
    <>
      <DefaultPage>
        <IonCard className="default-card">
          {/* <IonContent> */}
            <div className='default-card-wrapper'>
              <h2 className='default-card__list-title'>Profile</h2>
              <IonList>
                <IonItem button onClick={() => console.log('Logout')}>
                  <IonIcon slot="start" icon={personCircle} />
                  <IonLabel>Account</IonLabel>
                </IonItem>
                <LanguageSelector />
                <ThemeSelector onThemeChange={handleThemeChange} />
                <IonItem button onClick={logout}>
                  <IonIcon slot="start" icon={logOut} />
                  <IonLabel>Logout</IonLabel>
                </IonItem>
              </IonList>
            </div>
          {/* </IonContent> */}
        </IonCard>
      </DefaultPage>
    </>
  );
};

export default UserPage;
function setTheme(themeName: string) {
  document.body.className = themeName;
}