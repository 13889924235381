import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonLabel, IonThumbnail, IonIcon, IonImg, IonSpinner } from '@ionic/react';
import { playCircleOutline, pauseCircleOutline, openOutline, eyeOutline, saveOutline, downloadOutline, gridOutline, addOutline, planetOutline, volumeHighOutline, arrowDown, chevronCollapseOutline, chevronDown, chevronUp, pause, play } from 'ionicons/icons';
import './SongsList.css';

import { Song, SpotifyTrack, Word } from '../types/types.';
import { useHistory } from 'react-router';
import SongDetails from './SongDetails';

export interface SongsListType {
    // spotifyId?: string;
    name: string;
    songs?: Song[];
    tracks?: SpotifyTrack[];
}



const SongsList: React.FC<SongsListType> = ({ name, songs, tracks }) => {
    const { user, saveSong, getSongFullData, updateSong, togglePlaySong,updateWordsWithTranslations, setSelectedSpotifyTrack, generateSongInsights, playingTrackId, isPlaying, prepareSongWords, getLyrics, saveUserSong, generateLyricsVocabulary, selectedSong, setSelectedSong, saveWords } = useGlobalContext();
    const history = useHistory();
    const [showListItems, setShowListItems] = useState<boolean>(true);
    const navigateToItem = (track: SpotifyTrack) => {
        setSelectedSpotifyTrack(track)
        history.push(`/song/${track.id}`);
    };
    useEffect(() => {
        console.log("from SongsList tracks", tracks)

    }, [tracks]);
    const toggleListView = () => {
        setShowListItems(!showListItems)
        console.log("toggle")
    };

    const processLyricsAndWords = async (song: Song) => {
        console.log("prepareSongWords")
        if (song.id && song.lyrics && song.languageId) {
            const words = await prepareSongWords(song.lyrics, song.languageId, song.id, 3);
            console.log("words", words)
            if (words.length) {
                const proccessedWords = words.filter(w => w.translation)
                const unProccessedWords = words.filter(w => !w.translation).map(w => w.sourceWord)
                console.log("proccessedWords", proccessedWords)
                console.log("unProccessedWords", unProccessedWords)
                if (unProccessedWords.length) {
                    proccessWords(unProccessedWords, song.languageId, 3 )
                }
                else {
                    song.words = proccessedWords;
                    setSelectedSong(song);
                }
            }
        }
        // const sentences = await generateLyricsSentences(lyrics);
        // console.log("sentences", sentences)
        // await saveSongWords(songId, words);
        // await saveSongSentences(songId, sentences);
    };
    const proccessWords = async (words: string[], sourceLanguageId: number, translationLanguageId: number) => {
        const chunks = chunkArray(words);
        // let i = 0;
        for (const chunk of chunks) {
            // if (i >0) {
            //     break;
            // }
            const wordsResponse = await generateLyricsVocabulary(chunk, sourceLanguageId, translationLanguageId);
            if (wordsResponse && wordsResponse.length) {
                const result = await updateWordsWithTranslations(wordsResponse);
                console.log("Words update result:", result);
                // i++
            }
        }
    };
    const chunkArray = (array:string[], chunkSize = 10) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };
    const logClick = async (spotifyTrack: any) => {
        console.log("logClick spotifyTrack: ", spotifyTrack)
        // try {
        //     const song = await handleSongProcess(spotifyTrack, "3");
        //     if (song) {
        //         // עדכון הממשק
        //         console.log("Song processed successfully:", song);
        //         processLyricsAndWords(song)
        //     }
        // } catch (error) {
        //     console.error("Error processing song:", error);
        // }
    };
    const handleSongProcess = async (spotifyTrack: SpotifyTrack, userLanguageCode: string): Promise<Song | null> => {
        console.log("handleSongProcess");
        try {
            const existingSong = await getSongFullData(spotifyTrack.id, userLanguageCode);
            console.log("existingSong: ", existingSong)
            if (!existingSong) {
                return await addNewSong(spotifyTrack, userLanguageCode)
            }
            else if (!existingSong?.lyrics || !existingSong.difficultyLevelId || !existingSong.wordsCount || !existingSong.genreId || !existingSong.themeKeywords) {
                return await updateExistingSong(existingSong, spotifyTrack, userLanguageCode);
            }
            else {
                return existingSong;
            }

        } catch (error) {
            console.error("Error in handleSongProcess:", error);
            throw error;
        }
    };
    const addNewSong = async (spotifyTrack: SpotifyTrack, userLanguageCode: string) => {
        console.log("addNewSong")
        const newSongObj: Partial<Song> = {
            title: spotifyTrack.name,
            artist: spotifyTrack.artists?.[0]?.name || undefined,
            audioPreviewUrl: spotifyTrack.preview_url || undefined,
            spotifyId: spotifyTrack.id,
            albumUrl: spotifyTrack.album.images[0]?.url || undefined,
            isActive: false,
        };

        const savedSong = await saveSong(newSongObj);
        console.log("savedSong: ", savedSong)
        if (!savedSong?.id) {
            throw new Error('Failed to save basic song data');
        }
        const lyrics = await getLyrics(spotifyTrack);
        console.log("lyrics: ", lyrics)
        if (!lyrics) {
            throw new Error('Failed to fetch lyrics');
        }

        const insights = await generateSongInsights(lyrics);
        if (!insights) {
            throw new Error('Failed to generate song insights');
        }
        console.log("insights: ", insights)
        const updatedSong: Partial<Song> = {
            id: savedSong.id,
            lyrics,
            languageId: insights.languageId,
            difficultyLevelId: insights.difficultyLevelId,
            genreId: insights.genre,
            wordsCount: insights.wordsCount,
            themeKeywords: JSON.stringify(insights.themeKeywords),
            repetitiveWords: JSON.stringify(insights.repetitiveWords),
            idioms: JSON.stringify(insights.idioms)
        };

        await updateSong(updatedSong);

        return await getSongFullData(spotifyTrack.id, userLanguageCode);
    };
    const updateExistingSong = async (song: any, spotifyTrack: any, userLanguageCode: string) => {
        console.log("updateExistingSong")
        if (!song.lyrics)
            song.lyrics = await getLyrics(spotifyTrack);

        if (!song.lyrics) {
            throw new Error('Failed to fetch lyrics');
        }
        const insights = await generateSongInsights(song.lyrics);
        console.log("insights: ", insights)
        if (!insights) {
            throw new Error('Failed to generate song insights');
        }

        const updatedSong: Partial<Song> = {
            id: song.id,
            lyrics: song.lyrics,
            languageId: insights.languageId,
            difficultyLevelId: insights.difficultyLevelId,
            genreId: insights.genreId,
            wordsCount: insights.wordsCount,
            themeKeywords: JSON.stringify(insights.analysis.themeKeywords),
            repetitiveWords: JSON.stringify(insights.analysis.repetitiveWords),
            idioms: JSON.stringify(insights.analysis.idioms)
        };

        await updateSong(updatedSong);

        return await getSongFullData(spotifyTrack.id, userLanguageCode);
    };
    return (
        <div className='default-card-wrapper'>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='default-card__list-title'>{name}</p>
                <IonButton onClick={toggleListView} className='toggle-btn' fill='clear'>
                    <IonIcon icon={showListItems ? chevronUp : chevronDown} />
                </IonButton>
            </div>
            {showListItems && (
                <div className='default-card__list-wrapper'>
                    <IonList>
                        {songs && songs.length > 0 && songs.map((song) => (
                            <IonItem key={song.spotifyId}>
                                <div className='d-flex  default-card__content-right' style={{ width: '100%', padding: '8px' }}>
                                    <IonThumbnail style={{ heigth: '' }}>
                                        <img src={song.albumUrl} alt={song.title} />
                                    </IonThumbnail>
                                    {/* <IonLabel style={{ width: '80%', cursor: 'pointer' }} onClick={() => navigateToItem(song.spotifyId, 'song')}>
                                        <div className='d-flex align-items-center' style={{ maxWidth: '150px' }}>
                                            <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{song.title}</h2>
                                        </div>
                                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <i>{song.artist}</i>
                                        </div>
                                    </IonLabel> */}
                                </div>
                                <IonButton onClick={() => togglePlaySong(song)} className='action-btn'>
                                    <IonIcon icon={playingTrackId === song.spotifyId && isPlaying ? pause : play} />
                                </IonButton>
                            </IonItem>
                        ))}
                        {tracks && tracks.length > 0 && tracks.map((track) => (
                            <IonItem key={track.id} >
                                <div className='d-flex  default-card__content-right' style={{ width: '100%', padding: '8px' }}>
                                    <IonThumbnail style={{ heigth: '' }}>
                                        <img src={track.album.images[0]?.url} alt={track.name} />
                                    </IonThumbnail>
                                    <IonLabel style={{ width: '80%', cursor: 'pointer' }}>
                                        <div className='d-flex align-items-center' style={{ maxWidth: '150px' }}>
                                            <h2 className='f-bold' style={{ cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{track.name}</h2>
                                        </div>
                                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <i>{track.artists.map(artist => artist.name).join(', ')}</i>
                                        </div>
                                    </IonLabel>
                                </div>
                                <div className='action-btns'>
                                    <IonButton onClick={() => togglePlaySong(track)} className='action-btn'>
                                        <IonIcon icon={playingTrackId === track.id && isPlaying ? pause : play} />
                                    </IonButton>
                                    {/* <IonButton onClick={() => handleSaveSong(track)} className='action-btn'>
                                        <IonIcon icon={addOutline} />
                                    </IonButton> */}
                                    <IonButton onClick={() => logClick(track)} className='action-btn'>
                                        Log
                                    </IonButton>
                                    <IonButton onClick={() => navigateToItem(track)} className='action-btn'>
                                        Nav
                                    </IonButton>
                                </div>
                            </IonItem>
                        ))}
                    </IonList>
                </div>
            )}
            {/* {selectedSong && <SongDetails song={selectedSong}/>} */}
        </div>
    );
};

export default SongsList;

