import React, { useState, useEffect } from 'react';
import { IonSelect, IonSelectOption, IonItem, IonIcon } from '@ionic/react';
import { optionsOutline } from 'ionicons/icons';

const themes = [
  { name: 'Default', value: 'theme-default' },
  { name: 'Midnight Lagoon', value: 'theme-midnight-lagoon' },
  { name: 'Twilight Reef', value: 'theme-twilight-reef' },
  { name: 'Dark Mocha Leaf', value: 'theme-dark-mocha-leaf' },
  { name: 'Ocean Breeze', value: 'theme-ocean-breeze' }
];

interface ThemeSelectorProps {
  onThemeChange?: (theme: string) => void;
}

const ThemeSelector: React.FC<ThemeSelectorProps> = ({ onThemeChange }) => {
  const [currentTheme, setCurrentTheme] = useState<string>('theme-midnight-lagoon');

  useEffect(() => {
    document.body.className = currentTheme;
  }, []);

  const handleThemeChange = (event: CustomEvent) => {
    const selectedTheme = event.detail.value;
    setCurrentTheme(selectedTheme);
    document.body.className = selectedTheme;
    
    if (onThemeChange) {
      onThemeChange(selectedTheme);
    }
  };
  
  return (
    <IonItem>
      <IonIcon icon={optionsOutline} slot="start" />
      <IonSelect
        value={currentTheme}
        onIonChange={handleThemeChange}
        interface="popover"
        interfaceOptions={{
          cssClass: 'theme-selector-popover'
        }}
      >
        {themes.map((theme) => (
          <IonSelectOption key={theme.value} value={theme.value}>
            {theme.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export default ThemeSelector;