import axios from 'axios';
import { config } from '../config';
import { Word } from '../types/types.'

// export const getUnsplashImageForWorda = async (wordDesc: string): Promise<string | null> => {
//     console.log("getUnsplashImageForWord... word: ", wordDesc);
    

//     // מפתח ה-API של Unsplash
//     const accessKey = config.UNSPLASH_ACCESS_KEY;

//     // בניית ה-URL לחיפוש
//     const url = `https://api.unsplash.com/search/photos`;

//     try {
//         const response = await axios.get(url, {
//             headers: {
//                 'Authorization': `Client-ID ${accessKey}`
//             },
//             params: {
//                 query: wordDesc,
//                 per_page: 1,
//                 orientation: 'portrait',
//                 content_filter: 'high',
//                 order_by: 'relevant'
//             }
//         });

//         if (response.data.results && response.data.results.length > 0) {
//             console.log("unsplash results: ", response.data.results);
//             const photo = response.data.results[0];
//             // בחירת גודל תמונה אופטימלי - regular הוא בערך כמו medium ב-Pexels
//             return photo.urls.regular;
//         }
//         return "";
//     } catch (error) {
//         console.error('Error fetching image from Unsplash:', error);
//         return "";
//     }
// };
export const fetchImages = async (words: Word[], source: string) => {
    
    
    if (source === 'pexels') {
        return Promise.all(words.map(async (w) => {
            const wordDecs = w.wordTypeId === 1 ? `${w.englishDescription} isolated on white background` : w.wordTypeId === 2 ? `${w.englishDescription} action demonstration` : w.wordTypeId === 3 ? `${w.englishDescription} representation` : "";
            w.image = await getPexelsImageForWord(wordDecs);
            return w;
        }));
    }
    else if (source === 'unsplash') {
        return Promise.all(words.map(async (w) => {
            const wordDecs = w.wordTypeId === 1 ? `${w.englishDescription} isolated on white background` : w.wordTypeId === 2 ? `${w.englishDescription} action demonstration` : w.wordTypeId === 3 ? `${w.englishDescription} representation` : "";
            w.image = await getUnsplashImageForWord(wordDecs);
            return w;
        }));
    }
};

export const getPexelsImageForWord = async (word: string) => {
    console.log("getPexelsImageForWord... word: ", word)
    const prompt = `clear single ${word} object simple background educational`;

    const accessKey = process.env.REACT_APP_PEXELS_KEY;
    const url = `https://api.pexels.com/v1/search?query=${encodeURIComponent(word)}&per_page=1`;

    try {
        const response = await axios.get(url, {
            headers: { Authorization: accessKey }
        });

        if (response.data.photos && response.data.photos.length > 0) {
            console.log("pexels : ", response.data.photos)
            const photo = response.data.photos[0];
            return photo.src.medium;
        }
        return null;
    } catch (error) {
        console.error('Error fetching image from Pexels:', error);
        return null;
    }
};
export const getUnsplashImageForWord = async (word: string) => {
    console.log("getUnsplashImageForWord... word: ", word)
    const accessKey = config.UNSPLASH_ACCESS_KEY;

    // בניית ה-URL לחיפוש
    const url = `https://api.unsplash.com/search/photos`;

    try {
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Client-ID ${accessKey}`
            },
            params: {
                query: word,
                per_page: 1,
                orientation: 'portrait',
                content_filter: 'high',
                order_by: 'relevant'
            }
        });

        if (response.data.results && response.data.results.length > 0) {
            console.log("unsplash results: ", response.data.results);
            const photo = response.data.results[0];
            // בחירת גודל תמונה אופטימלי - regular הוא בערך כמו medium ב-Pexels
            return photo.urls.regular;
        }
        return null;
    } catch (error) {
        console.error('Error fetching image from Pexels:', error);
        return null;
    }
};