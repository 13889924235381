import React, { useEffect, useState } from 'react';
import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle, IonPage, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import AppHeader from '../components/AppHeader';
import './Home.css';
import DefaultPage from '../components/DefaultPage';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useGlobalContext } from '../contexts/GlobalContext';
import { contrastOutline, gridOutline, helpCircle, languageOutline, logOut, musicalNotes, pause, personCircle, play, settings, statsChart } from 'ionicons/icons';
import WordsModal from '../components/WordsModal';
import { Song, SpotifyTrack } from '../types/types.';
import SongDetails from '../components/SongDetails';

const SongPage: React.FC<RouteComponentProps> = (props) => {
  const { user, getTanslatedSongData, processNewSong, updateSong, togglePlaySong, updateWordsWithTranslations, generateLyricsSentences, generateSongInsights, playingTrackId, isPlaying, prepareSongWords, getLyrics, saveUserSong, generateLyricsVocabulary, selectedSong, setSelectedSong, saveWords, selectedSpotifyTrack, saveSongSentences } = useGlobalContext();
  const { spotifyId } = useParams<{ spotifyId: string }>();

  useEffect(() => {
    console.log("from SongPage: ")
    console.log(selectedSpotifyTrack)
    if (selectedSpotifyTrack) {
      togglePlaySong(selectedSpotifyTrack)
      proccessSpotifyTrack(selectedSpotifyTrack)
    }
  }, [user]);
  const proccessSpotifyTrack = async (spotifyTrack: any) => {
    setSelectedSong(null)
    console.log("proccessSpotifyTrack")
    try {
      const song = await getTanslatedSongData(spotifyTrack.id, user?.languageId.toString() || "3");
      if (song) {
        console.log("song: ", song)
        song.words = song.words ? song.words.filter(w => w.translation !== null) : []
        song.sentences = song.sentences ? song.sentences.filter(s => s.translation !== null) : []
        setSelectedSong(song)
        // if (song.words?.length) 
        // song.words.filter(w =>w)
        // setSelectedSong(song)
      }
      else {
        console.log("song not found")
        if (user?.languageId) {
          const newSong = await processNewSong(spotifyTrack, user?.languageId);
          setSelectedSong(newSong);
        }

      }
    } catch (error) {
      console.error("Error getSongFullData. error: ", error);
    }
  };
  // const handleSongProcess = async (spotifyTrack: SpotifyTrack, userLanguageCode: string): Promise<Song | null> => {
  //   console.log("handleSongProcess");
  //   try {
  //     const existingSong = await getSongFullData(spotifyTrack.id, userLanguageCode);
  //     console.log("existingSong: ", existingSong)
  //     if (!existingSong) {
  //       return await addNewSong(spotifyTrack, userLanguageCode)
  //     }
  //     else if (!existingSong?.lyrics || !existingSong.difficultyLevelId || !existingSong.wordsCount || !existingSong.genreId || !existingSong.themeKeywords) {
  //       return await updateExistingSong(existingSong, spotifyTrack, userLanguageCode);
  //     }
  //     else {
  //       return existingSong;
  //     }

  //   } catch (error) {
  //     console.error("Error in handleSongProcess:", error);
  //     throw error;
  //   }
  // };
  // const addNewSong = async (spotifyTrack: SpotifyTrack, userLanguageCode: string) => {
  //   console.log("addNewSong")
  //   const newSongObj: Partial<Song> = {
  //     title: spotifyTrack.name,
  //     artist: spotifyTrack.artists?.[0]?.name || undefined,
  //     audioPreviewUrl: spotifyTrack.preview_url || undefined,
  //     spotifyId: spotifyTrack.id,
  //     albumUrl: spotifyTrack.album.images[0]?.url || undefined,
  //     isActive: false,
  //   };

  //   const savedSong = await saveSong(newSongObj);
  //   console.log("savedSong: ", savedSong)
  //   if (!savedSong?.id) {
  //     throw new Error('Failed to save basic song data');
  //   }
  //   const lyrics = await getLyrics(spotifyTrack);
  //   console.log("lyrics: ", lyrics)
  //   if (!lyrics) {
  //     throw new Error('Failed to fetch lyrics');
  //   }

  //   const insights = await generateSongInsights(lyrics);
  //   if (!insights) {
  //     throw new Error('Failed to generate song insights');
  //   }
  //   console.log("insights: ", insights)
  //   const updatedSong: Partial<Song> = {
  //     id: savedSong.id,
  //     lyrics,
  //     languageId: insights.languageId,
  //     difficultyLevelId: insights.difficultyLevelId,
  //     genreId: insights.genre,
  //     wordsCount: insights.wordsCount,
  //     themeKeywords: JSON.stringify(insights.themeKeywords),
  //     repetitiveWords: JSON.stringify(insights.repetitiveWords),
  //     idioms: JSON.stringify(insights.idioms)
  //   };

  //   await updateSong(updatedSong);

  //   return await getSongFullData(spotifyTrack.id, userLanguageCode);
  // };
  // const updateExistingSong = async (song: any, spotifyTrack: any, userLanguageCode: string) => {
  //   console.log("updateExistingSong")
  //   if (!song.lyrics)
  //     song.lyrics = await getLyrics(spotifyTrack);

  //   if (!song.lyrics) {
  //     throw new Error('Failed to fetch lyrics');
  //   }
  //   const insights = await generateSongInsights(song.lyrics);
  //   console.log("insights: ", insights)
  //   if (!insights) {
  //     throw new Error('Failed to generate song insights');
  //   }

  //   const updatedSong: Partial<Song> = {
  //     id: song.id,
  //     lyrics: song.lyrics,
  //     languageId: insights.languageId,
  //     difficultyLevelId: insights.difficultyLevelId,
  //     genreId: insights.genreId,
  //     wordsCount: insights.wordsCount,
  //     themeKeywords: JSON.stringify(insights.analysis.themeKeywords),
  //     repetitiveWords: JSON.stringify(insights.analysis.repetitiveWords),
  //     idioms: JSON.stringify(insights.analysis.idioms)
  //   };

  //   await updateSong(updatedSong);

  //   return await getSongFullData(spotifyTrack.id, userLanguageCode);
  // };
  // const processLyricsAndWords = async (song: Song) => {
  //   console.log("prepareSongWords")
  //   if (song.id && song.lyrics && song.languageId) {
  //     const words = await prepareSongWords(song.lyrics, song.languageId, song.id, 3);
  //     console.log("words", words)
  //     if (words.length) {
  //       const proccessedWords = words.filter(w => w.translation)
  //       const unProccessedWords = words.filter(w => !w.translation).map(w => w.sourceWord)
  //       console.log("proccessedWords", proccessedWords)
  //       console.log("unProccessedWords", unProccessedWords)
  //       if (unProccessedWords.length) {
  //         proccessWords(unProccessedWords, song.languageId, 3)
  //       }
  //       else {
  //         song.words = proccessedWords;
  //         setSelectedSong(song);
  //       }
  //     }
  //     if (!song.sentences?.length) {
  //       const songSentencesResponse = await generateLyricsSentences(song.lyrics, song.languageId || 8, user?.languageId || 3);
  //       if (songSentencesResponse.sentences.length) {
  //         const result = await saveSongSentences(songSentencesResponse.sentences, song.id);
  //         console.log("songSentencesResponse result:", result);
  //       }
  //     }
  //   }

  // };
  // const proccessWords = async (words: string[], sourceLanguageId: number, translationLanguageId: number) => {
  //   const chunks = chunkArray(words);
  //   // let i = 0;
  //   for (const chunk of chunks) {
  //     // if (i >0) {
  //     //     break;
  //     // }
  //     const wordsResponse = await generateLyricsVocabulary(chunk, sourceLanguageId, translationLanguageId);
  //     if (wordsResponse && wordsResponse.length) {
  //       const result = await updateWordsWithTranslations(wordsResponse);
  //       console.log("Words update result:", result);
  //       // i++
  //     }
  //   }
  // };

  // const chunkArray = (array: string[], chunkSize = 10) => {
  //   const chunks = [];
  //   for (let i = 0; i < array.length; i += chunkSize) {
  //     chunks.push(array.slice(i, i + chunkSize));
  //   }
  //   return chunks;
  // };
  return (
    <>
      <DefaultPage>
        <IonCard className="default-card">
          {selectedSong && <SongDetails song={selectedSong} />}
        </IonCard>
      </DefaultPage>
    </>
  );
};

export default SongPage;
