import React, { useEffect, useState } from 'react';
import {
    IonPage,
    IonContent,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonLabel
} from '@ionic/react';
import { useGlobalContext } from '../contexts/GlobalContext';
import { Language } from '../types/types.';
import { language } from 'ionicons/icons';
import { useHistory } from 'react-router';

// טיפוס עבור המשתנים של השפה
type UserLanguage = 3 | 4 | 5 | 6 | 8;

const WelcomePage: React.FC = () => {
    const history = useHistory();
    const { user, updateUser, activeLanguages, getActiveLanguages } = useGlobalContext();
    const [isLoading, setIsLoading] = useState(true);
    const [userLanguage, setUserLanguage] = useState<UserLanguage>(4); // Default language (e.g., English)
    const [learningLanguages, setLearningLanguages] = useState<number[]>([]); // ברירת מחדל ריקה
    const [selectedGenres, setSelectedGenres] = useState<string[]>([]);

    const genres = [
        'Pop', 'Rock', 'Folk', 'Country',
        'Hip Hop / Rap', 'R&B', 'Indie'
    ];

    // תרגומים אמיתיים לשפות השונות
    const translations: {
        [key in UserLanguage]: {
            welcome: string;
            selectLanguage: string;
            learningLanguages: string;
            selectGenres: string;
            save: string;
        }
    } = {
        3: {
            welcome: 'ברוכים הבאים ל-SingSmart!',
            selectLanguage: 'בחר/י את השפה שלך',
            learningLanguages: 'אילו שפות תרצה/י ללמוד?',
            selectGenres: 'בחר/י את סגנונות השירה המועדפים עליך',
            save: 'בואו נתחיל!',
        },
        4: {
            welcome: 'Welcome to SingSmart!',
            selectLanguage: 'Select your language',
            learningLanguages: 'Which languages would you like to learn?',
            selectGenres: 'Choose your preferred music genres',
            save: "Let's Go!",
        },
        5: {
            welcome: '¡Bienvenido a SingSmart!',
            selectLanguage: 'Selecciona tu idioma',
            learningLanguages: '¿Qué idiomas te gustaría aprender?',
            selectGenres: 'Elige tus géneros musicales favoritos',
            save: '¡Vamos!',
        },
        6: {
            welcome: 'Bienvenue sur SingSmart!',
            selectLanguage: 'Sélectionne ta langue',
            learningLanguages: 'Quelles langues aimerais-tu apprendre?',
            selectGenres: 'Choisis tes genres musicaux préférés',
            save: 'Allons-y!',
        },
        8: {
            welcome: 'Benvenuto su SingSmart!',
            selectLanguage: 'Seleziona la tua lingua',
            learningLanguages: 'Quali lingue vorresti imparare?',
            selectGenres: 'Scegli i tuoi generi musicali preferiti',
            save: 'Andiamo!',
        }
    };

    useEffect(() => {
        if (user && user.isActive) {
            history.replace('/home');
        }
    }, [user]);
    useEffect(() => {
        const initializePage = async () => {
            setIsLoading(true);
            await getActiveLanguages();
            setIsLoading(false);
        };
        initializePage();
    }, []);

    useEffect(() => {
        console.log("user updated: ", user)
        if (user && activeLanguages) {
            const userLang = activeLanguages.find(lang => lang.id === user.languageId);
            if (userLang) setUserLanguage(userLang.id as UserLanguage);

            const learningLangs = activeLanguages.filter(lang => lang.id !== user.languageId);
            setLearningLanguages([]); // ברירת מחדל ריקה
            
        }
    }, [user, activeLanguages]);

    const handleUserLanguageSelect = (e: any) => {
        setUserLanguage(e.detail.value);
        const learningLangs = activeLanguages.filter(lang => lang.id !== e.detail.value);
        setLearningLanguages([]); // עדכון ברירת מחדל ריקה
    };

    const handleLearningLanguagesSelect = (e: any) => {
        setLearningLanguages(e.detail.value)
    };

    const handleGenresSelect = (e: any) => {
        setSelectedGenres(e.detail.value);
    };

    const handleSubmit = async () => {
        try {
            console.log("learningLanguages: ", learningLanguages)
            console.log("userLanguage: ", userLanguage)
            console.log("selectedGenres: ", selectedGenres)
            await updateUser("updateUser", {...user, languageId: userLanguage, favoriteGenres: selectedGenres, preferredLanguages: learningLanguages, isActive: true})
        } catch (error) {
            console.error('Error updating user preferences:', error);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <div className="welcome-container" dir={userLanguage === 3 ? 'rtl' : 'ltr'}>
                    <div>
                        <h1>{translations[userLanguage].welcome}</h1>

                        <div className='welcome-section'>
                            <h2 className='welcome-section__label'>{translations[userLanguage].selectLanguage}</h2>
                            <IonItem>
                                <IonSelect value={userLanguage} onIonChange={handleUserLanguageSelect} justify="start" >
                                    <div slot="label" className="select-label-container">
                                        <span className={`fi fi-${activeLanguages.find(lang => lang.id === userLanguage)?.flag}`}></span>
                                    </div>
                                    {activeLanguages.map((lang: Language) => (
                                        <IonSelectOption key={lang.id} value={lang.id}>
                                            {lang.localName}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            </IonItem>
                        </div>

                        <div className='welcome-section'>
                            <h2 className='welcome-section__label'>{translations[userLanguage].learningLanguages}</h2>
                            <IonItem>
                                <IonSelect
                                    value={learningLanguages}
                                    onIonChange={handleLearningLanguagesSelect}
                                    multiple={true} // מאפשר בחירה מרובה
                                    placeholder="Select languages"
                                >
                                    {activeLanguages
                                        .filter(lang => lang.id !== userLanguage) // לא מציג את השפה הנוכחית
                                        .map((lang: Language) => (
                                            <IonSelectOption key={lang.id} value={lang.id}>
                                                {lang.localName}
                                            </IonSelectOption>
                                        ))}
                                </IonSelect>
                            </IonItem>
                        </div>

                        <div className='welcome-section'>
                            <h2 className='welcome-section__label'>{translations[userLanguage].selectGenres}</h2>
                            <IonItem>
                                <IonSelect
                                    value={selectedGenres}
                                    onIonChange={handleGenresSelect}
                                    multiple={true}
                                    placeholder="Select genres"
                                >
                                    {genres.map((genre: string) => (
                                        <IonSelectOption key={genre} value={genre}>
                                            {genre}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            </IonItem>
                        </div>

                        <IonButton className='main-button' onClick={handleSubmit}>
                            {translations[userLanguage].save}
                        </IonButton>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default WelcomePage;




// import React, { useEffect, useState } from 'react';
// import {
//     IonPage,
//     IonHeader,
//     IonToolbar,
//     IonTitle,
//     IonContent,
//     IonItem,
//     IonLabel,
//     IonSelect,
//     IonSelectOption,
//     IonButton,
//     IonCheckbox,
//     IonList,
//     IonListHeader,
// } from '@ionic/react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import LanguageSelector from './LanguageSelector';
// import { useGlobalContext } from '../contexts/GlobalContext';
// const WellcomePage: React.FC = () => {
//     const { user, selectedLanguage, activeLanguages, updateUser, getActiveLanguages } = useGlobalContext();
//     const [userLanguage, setUserLanguage] = useState<number>(user && user.languageId || 4);
//     const [learningLanguages, setLearningLanguages] = useState<string[]>([]);


//     const genres = ['Pop', 'Rock', 'Jazz', 'Classical', 'Hip-Hop', 'Country'];
//     useEffect(() => {
//         console.log("wellcome new user")
//         getActiveLanguages();
//     }, []);
//     const handleSubmit = () => {
//         console.log("submit")
//     };

//     return (
//         <IonPage>
//             <IonContent className="ion-no-padding">
//                 <div className="wellcome-swiper-container">
//                     <Swiper
//                         pagination={{
//                             dynamicBullets: true,
//                         }}
//                         modules={[Pagination]}>
//                         <SwiperSlide>
//                             <div className='slide-content'>
//                                 <div>
//                                     wellcome to SingSmart...
//                                 </div>
//                                 <div>
//                                     my language selector
//                                 </div>
//                             </div>
//                         </SwiperSlide>
//                         <SwiperSlide>
//                             <div className='slide-content'>
//                                 lang to learn
//                             </div>
//                         </SwiperSlide>
//                         <SwiperSlide>
//                             <div>
//                                 <div>
//                                     Preferred genre
//                                 </div>
//                                 <IonButton expand="block" onClick={handleSubmit}>
//                                     Submit
//                                 </IonButton>
//                             </div>
//                         </SwiperSlide>
//                     </Swiper>
//                 </div>
//             </IonContent>
//         </IonPage>
//     );
// };

// export default WellcomePage;
